import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OurRoutes from "./Routes";
import * as Routers from "./Routers";
function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path={OurRoutes.ROOT} element={<Routers.ROOT />} />
          <Route path={OurRoutes.ABOUT_US} element={<Routers.ABOUT_US />} />
          <Route path={OurRoutes.CONTACT_US} element={<Routers.CONTACT_US />} />
          <Route
            path={OurRoutes.PROJECT_DETAILS}
            element={<Routers.PROJECT_DETAILS />}
          />
          <Route
            path={OurRoutes.PROJECT_UNITS}
            element={<Routers.PROJECT_UNITS />}
          />
          <Route
            path={OurRoutes.PROJECT_GALLERY}
            element={<Routers.PROJECT_GALLERY />}
          />
          <Route
            path={OurRoutes.SELECT_BROKER}
            element={<Routers.SELECT_BROKER />}
          />
          <Route
            path={OurRoutes.MY_MEETINGS}
            element={<Routers.MY_MEETINGS />}
          />
          <Route path={OurRoutes.BOOK_NOW} element={<Routers.BOOK_NOW />} />
          <Route
            path={OurRoutes.WAITING_SCREEN}
            element={<Routers.WAITING_SCREEN />}
          />
          <Route
            path={OurRoutes.PROJECT_DEALS}
            element={<Routers.PROJECT_DEALS />}
          />
          <Route
            path={OurRoutes.MY_INFORMATION}
            element={<Routers.MY_INFORMATION />}
          />
          <Route path={OurRoutes.NOT} element={<Routers.NOT />} />
          <Route path={OurRoutes.TRACKING} element={<Routers.TRACKING />} />
          <Route
            path={OurRoutes.CHANGE_PASSWORD}
            element={<Routers.CHANGE_PASSWORD />}
          />
          <Route path={OurRoutes.SETTINGS} element={<Routers.SETTINGS />} />
          <Route path={OurRoutes.DASHBOARD} element={<Routers.DASHBOARD />} />
          <Route
            path={OurRoutes.PRIMARY_PROJECTS}
            element={<Routers.PRIMARY_PROJECTS />}
          />
          <Route
            path={OurRoutes.SECONDARY_PROJECTS}
            element={<Routers.SECONDARY_PROJECTS />}
          />
          <Route
            path={OurRoutes.BROKER_COMMUNITY}
            element={<Routers.BROKER_COMMUNITY />}
          />
          <Route
            path={OurRoutes.BROKER_LEADS}
            element={<Routers.BROKER_LEADS />}
          />
          <Route path={OurRoutes.SUPPORT} element={<Routers.SUPPORT />} />
          <Route path={OurRoutes.PAYMENTS} element={<Routers.PAYMENTS />} />
          <Route
            path={OurRoutes.PAYMENT_TRACKING}
            element={<Routers.PAYMENT_TRACKING />}
          />
          <Route
            path={OurRoutes.BROKER_MY_INFORMATION}
            element={<Routers.BROKER_MY_INFORMATION />}
          />
          <Route
            path={OurRoutes.BROKER_ACCOUNT}
            element={<Routers.BROKER_ACCOUNT />}
          />
          <Route path={OurRoutes.NEED_HELP} element={<Routers.NEED_HELP />} />
          <Route
            path={OurRoutes.FORGOT_PASSWORD}
            element={<Routers.FORGOT_PASSWORD />}
          />
        </Routes>
        {/* <Routes>
                    <Route path={OurRoutes.INDEXS} element={<Routers.INDEXS />} />
                </Routes> */}
      </Suspense>
    </Router>
  );
}
export default AppRouter;
