import React from "react";

export const ROOT = React.lazy(() => import("../component/home"));
export const ABOUT_US = React.lazy(() => import("../component/aboutus"));
export const CONTACT_US = React.lazy(() => import("../component/contactus"));
export const PROJECT_DETAILS = React.lazy(() =>
  import("../component/projectDetails")
);
export const PROJECT_UNITS = React.lazy(() =>
  import("../component/projectunits")
);
export const PROJECT_GALLERY = React.lazy(() => import("../component/gallery"));
export const SELECT_BROKER = React.lazy(() =>
  import("../component/selectBroker")
);
export const MY_MEETINGS = React.lazy(() => import("../component/myMeetings"));
export const BOOK_NOW = React.lazy(() => import("../component/booknow"));
export const WAITING_SCREEN = React.lazy(() =>
  import("../component/waitingscreen")
);
export const PROJECT_DEALS = React.lazy(() =>
  import("../component/projectdeals")
);
export const MY_INFORMATION = React.lazy(() => import("../component/myinfo"));
export const NOT = React.lazy(() => import("../component/subNotify"));
export const TRACKING = React.lazy(() =>
  import("../component/projectTracking")
);
export const CHANGE_PASSWORD = React.lazy(() =>
  import("../component/changePassword")
);
export const SETTINGS = React.lazy(() => import("../component/settings"));
export const DASHBOARD = React.lazy(() =>
  import("../component/Broker/dashboard")
);
export const PRIMARY_PROJECTS = React.lazy(() =>
  import("../component/Broker/Projects/primary")
);
export const SECONDARY_PROJECTS = React.lazy(() =>
  import("../component/Broker/Projects/secondary")
);
export const BROKER_COMMUNITY = React.lazy(() =>
  import("../component/Broker/Meetings/communityCountry")
);
export const BROKER_LEADS = React.lazy(() =>
  import("../component/Broker/Meetings/leads")
);
export const SUPPORT = React.lazy(() =>
  import("../component/Broker/Meetings/support")
);
export const PAYMENTS = React.lazy(() =>
  import("../component/Broker/Payments/payments")
);
export const PAYMENT_TRACKING = React.lazy(() =>
  import("../component/Broker/Payments/tracking")
);
export const BROKER_MY_INFORMATION = React.lazy(() =>
  import("../component/Broker/Dashboard/myInformation")
);
export const BROKER_ACCOUNT = React.lazy(() =>
  import("../component/Broker/Dashboard/myAccount")
);
export const NEED_HELP = React.lazy(() => import("../component/NeedHelp"));
export const FORGOT_PASSWORD = React.lazy(() =>
  import("../component/ForgotPassword")
);

// export const INDEXS = React.lazy(() => import('../component/indexs/indexs'));
// export const INDEXSNAV = React.lazy(() => import('../component/indexs/indexsnav'));
// export const INDEXSFOOTER = React.lazy(() => import('../component/indexs/indexsfooter'));
// export const INDEXSBANNER = React.lazy(() => import('../component/indexs/indexsbanner'));
// export const INDEXSHOMEOTHERS = React.lazy(() => import('../component/indexs/indexshomeothers'));
