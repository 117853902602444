import {
  BROKER_ACCOUNT,
  BROKER_MY_INFORMATION,
  PAYMENTS,
  SUPPORT,
} from "./Routers";

const routes = {
  ROOT: "/",
  ABOUT_US: "/about",
  CONTACT_US: "/contact",
  PROJECT_DETAILS: "/projectDetails",
  PROJECT_UNITS: "/projectUnits",
  PROJECT_GALLERY: "/projectImages",
  SELECT_BROKER: "/selectBroker",
  MY_MEETINGS: "/myMeetings",
  BOOK_NOW: "/booknow",
  WAITING_SCREEN: "/wait",
  PROJECT_DEALS: "/projectdeals",
  MY_INFORMATION: "/myInfo",
  NOT: "/not",
  TRACKING: "/projTracking",
  CHANGE_PASSWORD: "/changePassword",
  SETTINGS: "/settings",
  DASHBOARD: "/dashboard",
  PRIMARY_PROJECTS: "/primaryprojects",
  SECONDARY_PROJECTS: "/secondaryprojects",
  BROKER_COMMUNITY: "/community",
  BROKER_LEADS: "/leads",
  SUPPORT: "/support",
  PAYMENTS: "/payments",
  PAYMENT_TRACKING: "/tracking",
  BROKER_MY_INFORMATION: "/brokerinformation",
  BROKER_ACCOUNT: "/myaccount",
  NEED_HELP: "/needhelp",
  FORGOT_PASSWORD: "/forgotpassword",

  // INDEXS: '/indexs'
};
export default routes;
